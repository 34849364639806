export default {
    common: {
        loading: 'Loading..',
        somethingWentWrong: 'Something went wrong!',
        close: 'Close',
        submit: 'Submit',
        cancel: 'Cancel',
        ok: 'OK',
        refresh: 'Refresh',
        yes: 'Yes',
        no: 'No',
        enter: 'Enter',
        create: 'Create'
    },
    limetype: {
        saveChanges: 'Save changes',
        noChanges: 'No changes made',
        updateSuccess: 'Success! The object was updated',
        requestError: code => `Request failed with status code: ${code}`,
        error: 'Something went wrong, please try again and contact an administrator if the problem persists.',
        filter: 'Filter',
        noData: 'No data found'
    },
    auth: {
        login: 'Login',
        loginUsingBankId: 'Login using BankID',
        loginUsingCredentials: 'Sign in with credentials',
        loginWith: 'Sign in with',
        userDetails: 'User Details',
        forgotPassword: 'Forgot your password?',
        forgotPasswordInfo: "Enter your username and we'll send you a link to a page where you can reset your password.",
        resetLinkSent: 'A reset email has been sent!',
        usernameLabel: 'Username',
        passwordLabel: 'Password',
        loginSuccess: 'You are now logged in!',
        personalNumberInputLabel: 'YYYYMMDDNNNN',
        personalNumberLabel: 'Personal number',
        emailLabel: 'Email',
        emailInputLabel: 'Enter your email',
        resetPassword: 'Reset Password',
        newPasswordLabel: 'New password',
        newPasswordInputLabel: 'Choose a new password',
        confirmPasswordLabel: 'Confirm new password',
        resetPasswordSuccess: 'Your password has been reset. You will now be redirected to the login page where you can login using your new password.',
        "urn:grn:authn:no:bankid": 'Mobile or Web',
        "urn:grn:authn:no:vipps": 'Vipps',
        "urn:grn:authn:no:bankid:central": "Web",
        "urn:grn:authn:no:bankid:mobile": "Mobile",
        "urn:grn:authn:dk:nemid:poces": "Personal with code card",
        "urn:grn:authn:dk:nemid:moces": "Employee with code card",
        "urn:grn:authn:dk:nemid:moces:codefile": "Employee with code file",
        "urn:grn:authn:se:bankid:another-device": "BankID on another device",
        "urn:grn:authn:se:bankid:same-device": "BankID on this device",
        authMethodInputLabel: 'Choose a method',
        authMethodLabel: 'Authentication method',
        phoneNumberLabel: 'Mobile',
        phoneNumberInputLabel: 'XXXXXXXX',
        activateAccountInfo: "Enter your username and we'll send you a link to a page where you can set your password",
        activateAccount: "Activate your account",
        activationLinkSent: "The activation mail has been sent!",
        loginAgain: "Sign in again",
        loggedOut: "You are logged out!",
        selectEntityTitle: "Select entity", // title text on entity page
        selectEntityButtonText: "Continue", // button text for "submit"
    },
    nav: {
        loggedInAs: 'Logged in as',
        logout: 'Logout',
        start: 'Start',
        index: 'List',
        create: 'Create',
        switchEntity: "Switch entity",
    },
    startView: 'Start View',
    startViewNotConfigured: 'No start view configured. See /readme.txt in the root folder for instructions.',
    pageNotFoundTitle: 'Whoops, 404',
    pageNotFoundText: 'The page could not be found.',
    languagePicker: {
        da: "Danish",
        de: "German",
        en: "English",
        fi: "Finnish",
        nl: "Dutch",
        no: "Norwegian",
        sv: "Swedish"
    }
}