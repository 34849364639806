var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-content",
    [
      _c(
        "v-container",
        { attrs: { fluid: "", "fill-height": "" } },
        [
          _c(
            "v-row",
            {
              staticClass: "mx-0",
              attrs: { align: "center", justify: "center" },
            },
            [
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6", md: "5", lg: "4", xl: "3" } },
                [
                  _vm.logo
                    ? _c("img", {
                        staticClass: "mb-3 d-block",
                        staticStyle: { "max-width": "200px", margin: "auto" },
                        attrs: { src: "/domain/" + _vm.logo },
                        on: {
                          "~error": function ($event) {
                            return _vm.logoFallback($event)
                          },
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-card",
                    {
                      staticClass: "elevation-12 py-4",
                      attrs: { id: "login-card" },
                    },
                    [
                      _c(
                        "v-card-text",
                        {
                          staticClass:
                            "d-flex justify-space-around align-center flex-column",
                        },
                        [
                          _c("h1", { staticClass: "mb-8" }, [
                            _vm._v(_vm._s(_vm.$t("auth.loggedOut"))),
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mb-4",
                              attrs: { color: "primary" },
                              on: { click: _vm.goToLogin },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t" +
                                  _vm._s(_vm.$t("auth.loginAgain")) +
                                  "\n\t\t\t\t\t\t"
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.isLoading
                            ? _c("v-progress-circular", {
                                staticClass: "mr-2",
                                attrs: {
                                  size: 20,
                                  color: "primary",
                                  indeterminate: "",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }