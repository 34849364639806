<template>
	<v-content>
		<v-container fluid fill-height>
			<v-row align="center" justify="center" class="mx-0">
				<v-col cols="12" sm="6" md="5" lg="4" xl="3">
					<img
						:src="`/domain/${logo}`"
						@error.once="logoFallback"
						v-if="logo"
						class="mb-3 d-block"
						style="max-width: 200px; margin: auto"
					/>
					<v-card class="elevation-12 py-4" id="login-card">
						<v-card-text
							class="d-flex justify-space-around align-center flex-column"
						>
							<h1 class="mb-8">{{ $t("auth.loggedOut") }}</h1>
							<v-btn class="mb-4" color="primary" @click="goToLogin">
								{{ $t("auth.loginAgain") }}
							</v-btn>
							<v-progress-circular
								v-if="isLoading"
								:size="20"
								color="primary"
								class="mr-2"
								indeterminate
							></v-progress-circular>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
		</v-container>
	</v-content>
</template>

<script>
export default {
	data() {
		return {
			isLoading: false,
		};
	},

	computed: {
		logo() {
			return _.get(window, "portal.logo_filename", null);
		},
	},

	methods: {
		goToLogin() {
			this.isLoading = true;
			window.location.pathname = "/";
		},

		logoFallback(e) {
			e.target.src = `/storage/${this.logo}`;
		},
	},
};
</script>
