<template>
    <v-dialog v-model="show" max-width="500px">
      <template v-slot:activator="{ on }">
        <v-btn
          text
          color="primary"
          ripple
          v-on="on"
          small
        >
          {{ $t('auth.forgotPassword') }}
        </v-btn>
      </template>
      <v-card>
        <ValidationObserver ref="obs" tag="form">
          <v-card-text class="pt-4">
            <p>{{ $t('auth.forgotPasswordInfo') }}</p>

            <validation-provider :name="$t('auth.usernameLabel')" rules="required">
              <v-text-field
                ref="usernameInput"
                v-model="username"
                prepend-icon="person"
                :label="$t('auth.usernameLabel')"
                type="text"
                slot-scope="{ errors  }"
                :error-messages="errors"
                :loading="loading"
                :disabled="loading"
              ></v-text-field>
            </validation-provider>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="grey"
              text
              @click="show = false"
            >
              {{ $t('common.close') }}
            </v-btn>

            <v-btn
              color="primary"
              type="submit"
              text
              @click.prevent="submit"
              :loading="loading"
              :disabled="loading"
            >
              {{ $t('common.submit') }}
            </v-btn>
          </v-card-actions>
        </ValidationObserver>
      </v-card>
    </v-dialog>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
  },

  watch: {
    show: async function(val) {
      if (val === true) {
        await this.$nextTick();

        this.$refs.usernameInput.focus();
      }
    }
  },

  data() {
    return {
      username: '',
      show: false,
      loading: false,
      errorMessage: null
    }
  },

  methods: {
    async submit() {
      let valid = await this.$refs.obs.validate();

      if (! valid) return;

      this.loading = true;

      try {
        const response = await axios.post(this.route('custom-limetype.forgot.request'), {
          username: this.username
        });

        this.$store.commit('snack/SET', {
          message: this.$t('auth.resetLinkSent'),
          color: 'success',
          showCloseButton: true,
          timeout: -1
        });

        this.show = false;
        this.username = '';
      } catch (err) {
        console.log(err);
      }

      this.loading = false;
    }
  }

}
</script>