var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-snackbar",
    {
      attrs: { timeout: _vm.timeout, color: _vm.color },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _vm._v("\n  " + _vm._s(_vm.message) + "\n  "),
      _c(
        "v-btn",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showCloseButton,
              expression: "showCloseButton",
            },
          ],
          attrs: { text: "" },
          nativeOn: {
            click: function ($event) {
              _vm.show = false
            },
          },
        },
        [_vm._v("\n    " + _vm._s(_vm.$t("common.close")) + "\n  ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }