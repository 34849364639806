<template>
  <ValidationObserver ref="obs" tag="form">
    <v-card class="elevation-12" slot-scope="{ invalid }">
      <v-toolbar dark color="primary" flat>
        <v-toolbar-title>{{ $t("auth.loginUsingBankId") }}</v-toolbar-title>
        <v-spacer />
        <language-picker
          :userIsAuthenticated="false"
          :resetFieldsAfterChange="[$refs.obs]"
        />
      </v-toolbar>
      <v-card-text class="criipto-login" id="login-card">
        <validation-provider
          :name="$t('auth.authMethodLabel')"
          rules="required"
          v-if="loginMethods.length > 1"
        >
          <v-select
            solo
            :items="loginMethods"
            item-text="name"
            item-value="value"
            v-model="form.identification_method"
            :label="$t('auth.authMethodInputLabel')"
            slot-scope="{ errors }"
            :error-messages="errors"
            append-icon="expand_more"
            :disabled="loading"
            :loading="loading"
            name="authentication-method"
          ></v-select>
        </validation-provider>

        <!-- <v-icon medium style="font-size: 36px;">{{ settings.logo }}</v-icon> -->
        <validation-provider
          :name="$t('auth.personalNumberLabel')"
          :rules="
            form.identification_method === null ? 'required' : settings.rule
          "
          v-if="form.identification_method !== null"
        >
          <v-text-field
            solo
            clearable
            :hide-details="!invalid"
            :label="$t('auth.personalNumberInputLabel')"
            type="tel"
            v-model="form.personalNumber"
            slot-scope="{ errors }"
            :error-messages="errors"
            :disabled="loading || form.identification_method === null"
            :loading="loading"
            class="mb-2 auth-method"
          >
            <template v-slot:prepend-inner>
              <v-icon>{{ settings.logo }}</v-icon>
              <v-divider class="mx-2" light vertical></v-divider>
            </template>
          </v-text-field>
        </validation-provider>

        <validation-provider
          :name="$t('auth.phoneNumberLabel')"
          rules="required|digits:8"
          v-if="form.identification_method === 'urn:grn:authn:no:bankid:mobile'"
        >
          <v-text-field
            solo
            clearable
            :hide-details="!invalid"
            :label="$t('auth.phoneNumberInputLabel')"
            type="tel"
            v-model="form.mobileNumber"
            slot-scope="{ errors }"
            :error-messages="errors"
            :disabled="loading"
            :loading="loading"
            class="mb-2"
          >
            <template v-slot:prepend-inner>
              <v-icon>phone</v-icon>
              <v-divider class="mx-2" light vertical></v-divider>
            </template>
          </v-text-field>
        </validation-provider>

        <v-row
          class="mt-4"
          v-if="loading"
          align-content="center"
          align="center"
          justify="center"
        >
          <v-progress-circular
            :size="20"
            color="primary"
            class="mr-2"
            indeterminate
          ></v-progress-circular>
          {{ status }}
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          type="submit"
          :loading="loading"
          :disabled="loading"
          @click.prevent="submit"
        >
          {{ $t("auth.login") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </ValidationObserver>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import LanguagePicker from "../LanguagePicker.vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    LanguagePicker,
  },

  data() {
    return {
      form: {
        personalNumber: "",
        identification_method: null,
        mobileNumber: "",
      },
      interval: null,
      loading: false,
      status: this.$t("common.loading"),
      loginMethods: [],
    };
  },

  methods: {
    async submit() {
      let valid = await this.$refs.obs.validate();
      console.log("valid?", valid);
      if (!valid) return;

      this.loading = true;

      let locale = this.$store.getters.getUserLocale();

      axios.post(this.route("criipto.precheck").toString(), {
            personal_number: this.form.personalNumber,
          },{
            headers: {
              "Accept-Language": locale,
            },
          }
				).then((res) => {
          if (res.data.success && res.data.next) {
            window.location.href = `${res.data.next}&${this.redirectParams()}`;
          } else {
            this.loading = false;
            this.$store.commit("snack/SET", {
              message: _.get(
                err,
                "response.data.message",
                this.$t("common.somethingWentWrong")
              ),
              color: "error",
            });
            throw new Error();
          }
        })
        .catch((err) => {
          this.loading = false;
          this.$store.commit("snack/SET", {
            message: _.get(
              err,
              "response.data.message",
              this.$t("common.somethingWentWrong")
            ),
            color: "error",
          });
        });
    },

    init() {
      console.log("methods", process.env.MIX_CRIIPTO_AUTH_METHODS);
      this.loginMethods = (process.env.MIX_CRIIPTO_AUTH_METHODS || "")
        .split(",")
        .map((obj) => {
          console.log(obj);
          return {
            name: this.$t(`auth.${obj}`),
            value: obj,
          };
        });

      if (this.loginMethods.length < 2)
        this.form.identification_method = this.loginMethods[0].value;
    },

    loginHint() {
      // format the different types of login hints for each method
      // for NO, see https://confluence.bankidnorge.no/confluence/pdoidcl/technical-documentation/core-concepts/identity-providers
      if (this.form.identification_method.indexOf("dk:nemid") !== -1) {
        return `sub:${this.form.personalNumber}`;
      } else if (
        this.form.identification_method.indexOf("no:bankid:central") !== -1
      ) {
        return `BID:${this.form.personalNumber}`;
      } else if (
        this.form.identification_method.indexOf("no:bankid:mobile") !== -1
      ) {
        return `BIM:${
          this.form.mobileNumber
        }:${this.form.personalNumber.substring(0, 6)}`;
      } else if (this.form.identification_method.indexOf(":no:vipps") !== -1) {
        // not documented in Criipto
        return "";
      } else if (this.form.identification_method.indexOf("se:bankid") !== -1) {
        return `sub:${this.form.personalNumber}`;
      }
      return "";
    },

    redirectParams() {
      // let this only return the params, and return the criipto specifics from the controller.
      let url = "response_type=code&";
      url += `acr_values=${this.form.identification_method}&`;
      //url += `acr_values=${this.form.identification_method.indexOf('no:bankid') !== -1 ? 'urn:grn:authn:no:bankid' : this.form.identification_method}&`;
      url += `login_hint=${this.loginHint()}&`;
      url += "scope=openid&";
      url += "state=etats";

      return url;
    },
  },

  computed: {
    settings() {
      console.log("@ssnRule", this.form.identification_method);

      // request the format we need for the precheck in Lime, regardless of what criipto expects in login_hint
      if (!this.form.identification_method) {
        return {
          rule: "required",
          logo: "",
        };
      } else if (this.form.identification_method.indexOf("no:vipps") !== -1) {
        return {
          rule: "required|digits:11",
          logo: "$vuetify.icons.vipps",
        };
      } else if (this.form.identification_method.indexOf("dk:nemid") !== -1) {
        return {
          rule: "required|digits:10",
          logo: "$vuetify.icons.nemid",
        };
      } else if (
        this.form.identification_method.indexOf("no:bankid:central") !== -1
      ) {
        return {
          rule: "required|digits:11",
          logo: "$vuetify.icons.nobankid",
        };
      } else if (
        this.form.identification_method.indexOf("no:bankid:mobile") !== -1
      ) {
        return {
          rule: "required|digits:11",
          logo: "$vuetify.icons.nobankid",
        };
      } else if (this.form.identification_method.indexOf("dk:mitid") !== -1) {
        return {
          rule: "required|digits:10",
          logo: "$vuetify.icons.mitid",
        };
      } else if (this.form.identification_method.indexOf("se:bankid") !== -1) {
        return {
          rule: "required|digits:12",
          logo: "$vuetify.icons.bankid",
        };
      } else if (this.form.identification_method.indexOf("no:bankid") !== -1) {
        return {
          rule: "required|digits:11",
          logo: "$vuetify.icons.nobankid",
        };
      }

      return "required";
    },

    showInput() {
      if (!this.form.identification_method) return false;

      return (
        _.findIndex(
          this.preFillableMethods,
          (method) => method === this.form.identification_method
        ) !== -1
      );
    },
  },

  mounted() {
    this.init();

    if (window.portal.message) {
      console.log("has message");
      this.$store.commit("snack/SET", {
        message: window.portal.message.message,
        color: window.portal.message.color,
        showCloseButton: true,
        timeout: 0,
      });
    }
  },
};
</script>
<style scoped>
.criipto-login img {
  font-size: 48px;
  width: 48px;
  height: 100%;
  align-self: center;
}
.criipto-login img.nemid {
  height: 25% !important;
}
.criipto-login img.sebankid {
  width: auto;
}
.criipto-login .v-icon {
  align-self: center;
  width: 48px;
  height: 24px;
}
</style>
<style>
.criipto-login .v-input__prepend-inner {
  align-self: normal !important;
}
#login-card input:-webkit-autofill,
#login-card input:-internal-autofill-selected {
  background-color: inherit;
}
</style>