export default {
  darkMode: "Donkere modus",
  helpdeskTileMessage: "Nieuwe gevallen vandaag",
  messageTile: {
    singular: "nieuw bericht",
    plural: "nieuwe berichten"
  },
  limetypeInspector: {
    labelFieldtype: "Toon alleen velden die een veldtype van bevatten...",
    labelObject: "Voorwerp",
    title: "Blader door het Lime-shcema...",
    properties: "Eigenschappen:",
  },
  menu: {
    coworkers: "Coworkers (nl)",
    deals: "Show deals (nl)",
    helpdeskShow: "Show tickets (nl)",
    helpdeskCreate: "Create ticket (nl)",
    pagination: "Paginering (nl)",
    form: "Form #1 (nl)",
    grouped: "Grouped (nl)",
  }
}