import _ from 'lodash';

const createAsyncMutation = (type) => ({
  SUCCESS: `${type}_SUCCESS`,
  FAILURE: `${type}_FAILURE`,
  PENDING: `${type}_PENDING`,
  loadingKey: _.camelCase(`${type}_PENDING`),
  stateKey: _.camelCase(`${type}_DATA`),
  lastFetched: _.camelCase(`${type}_LAST_FETCHED`)
});

export default {
  LIMETYPE_INDEX: createAsyncMutation('LIMETYPE_INDEX'),
  LIMETYPE_SHOW: createAsyncMutation('LIMETYPE_SHOW'),
  LIMETYPE_RELATION: createAsyncMutation('LIMETYPE_RELATION'),
  LIMETYPE_CREATE: 'LIMETYPE_CREATE',

}