<template>
  <img :src="image" class="sebankid"/>
</template>


<script>
import BankIdLogo from './bankid_logo.png';

export default {
  data() {
    return {
      image: BankIdLogo
    }
  }
}
</script>
