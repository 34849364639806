import _ from 'lodash';
import { Validator } from 'vee-validate';

export default {
  state: {
    session: localStorage,
    userLocale: localStorage.getItem('lime_portal_user_language'),
    validator: Validator,
    enabledLanguages: _.get(window, "portal.enabled_languages", []),
  },
  getters: {
    getUserLocale: (state) => () => {
      let locale = state.enabledLanguages.includes(state.userLocale) ? state.userLocale : _.get(window, 'portal.default_locale');

      return locale.includes('_') ? locale.split('_')[0] : locale;
    },
  },
  mutations: {
    'SET_USER_LOCALE': (state, {selected = null, i18n = null}) => {
      selected = selected == 'en_us' ? 'en' : selected; // Might be unnececessary?
      selected = state.enabledLanguages.includes(selected) ? selected : '';
      let locale = i18n.availableLocales.includes(selected) ? selected : '';

      if (locale == '') {
        // Get default locale from config, if omitted use 'en'
        locale = _.get(window, 'portal.default_locale', 'en');
        console.err("Lime Portal - Incorrect or unsupported locale value. Using configured default locale");
      }

      try {
        state.userLocale = locale;
        // Update validator locale, i18n locale, store choice in session/localStorage
        i18n.locale = locale;
        state.session.setItem('lime_portal_user_language', locale);
        state.validator.locale = locale;
      } catch (e) {
        console.warn("Something went wrong when setting locale: ", e);
      }
    },
  }
};