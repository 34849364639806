export default {
  darkMode: "Mørk modus",
  helpdeskTileMessage: "Nye saker i dag",
  messageTile: {
    singular: "ny melding",
    plural: "nye meldinger"
  },
  limetypeInspector: {
    labelFieldtype: "Vis kun felt som inneholder en felttype av...",
    labelObject: "Objekt",
    title: "Bla gjennom Lime-skjemaet...",
    properties: "Eiendom:",
  },
  menu: {
    coworkers: "Coworkers (no)",
    deals: "Show deals (no)",
    helpdeskShow: "Show tickets (no)",
    helpdeskCreate: "Create ticket (no)",
    pagination: "Paginering (no)",
    form: "Form #1 (no)",
    grouped: "Grouped (no)",
  }
}