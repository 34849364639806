<template>
	<v-content>
		<v-container fluid fill-height>
			<v-row align="center" justify="center" class="mx-0">
				<v-col cols="12" sm="6" md="5" lg="4" xl="3">
					<img
						v-if="logo"
						:src="`/domain/${logo}`"
						@error.once="logoFallback"
						class="mb-3 d-block"
						:alt="companyName"
						style="max-width: 200px; margin: auto"
					/>
					<v-card class="elevation-12" id="login-card">
						<v-divider class="mx-4"></v-divider>
						<v-card-text class="d-flex flex-column">
							<v-expansion-panels>
								<v-expansion-panel
									:class="index !== availableMethods.length - 1 ? 'mb-4' : ''"
									v-for="(method, index) in availableMethods"
									:key="index"
								>
									<v-expansion-panel-header
										elevation="2"
										:class="method"
										:disabled="isLoading"
										@click="selected(method)"
										:color="providers[method].bgColor"
									>
										<template v-slot:actions>
											<v-icon>
												{{ providers[method].panelIcon }}
											</v-icon>
										</template>
										<v-progress-circular
											v-if="method === 'openid' && isLoading"
											:size="20"
											:color="providers[method].color"
											class="mr-2"
											indeterminate
										></v-progress-circular>
										<div class="d-flex align-center" v-else>
											<v-icon
												size="36px"
												:color="providers[method].color"
												class="mr-4"
												v-if="providers[method].logo.indexOf('/') != false"
											>
												{{ providers[method].logo }}
											</v-icon>
											<v-img
												class="mr-4"
												contain
												v-else
												max-height="36px"
												:max-width="providers[method].maxWidth"
												:src="providers[method].logo"
											></v-img>
											<span :style="`color: ${providers[method].color};`">{{
												providers[method].text
											}}</span>
										</div>
									</v-expansion-panel-header>
									<v-expansion-panel-content
										:key="index"
										v-if="authMethod !== 'openid'"
									>
										<LoginMethod
											:is="loginComponent"
											:auth-method="authMethod"
											:key="authMethod"
										/>
									</v-expansion-panel-content>
								</v-expansion-panel>
							</v-expansion-panels>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
		</v-container>
	</v-content>
</template>

<script>
import BankId from "./methods/wrapped/BankId";
import Credentials from "./methods/wrapped/Credentials";
import Criipto from "./methods/wrapped/Criipto";
import bankidLogo from "../components/Icons/bankid_logo.png";

export default {
	props: {
		availableMethods: {
			type: Array,
			default: () => [],
		},
	},

	data() {
		return {
			authMethod: null,
			isLoading: false,
			providers: {
				bankid: {
					bgColor: "#fff",
					color: "#183e4f",
					text: this.$t("auth.loginUsingBankId"),
					logo: bankidLogo,
					maxWidth: "2rem",
					maxHeight: "1rem",
					panelIcon: "mdi-chevron-down",
				},
				custom_limetype: {
					bgColor: "primary",
					color: "#fff",
					text: this.$t("auth.loginUsingCredentials"),
					logo: "mdi-account",
					panelIcon: "mdi-chevron-down",
				},
				lime_user: {
					bgColor: "primary",
					color: "#fff",
					text: this.$t("auth.loginUsingCredentials"),
					logo: "mdi-account",
					panelIcon: "mdi-chevron-down",
				},
				openid: {
					bgColor: "rgb(251, 140, 0)",
					color: "#fff",
          text: `${this.$t("auth.loginWith")} ${window.portal.openidProvider}`,
					logo: "mdi-openid",
					panelIcon: "mdi-arrow-right",
				},
        criipto: {
          bgColor: "rgb(251, 140, 0)",
          color: "#fff",
          text: this.$t("auth.loginUsingBankId"),
          logo: "mdi-openid",
          panelIcon: "mdi-chevron-down"
        }
			},
		};
	},

	components: {
		"method-custom_limetype": Credentials,
		"method-lime_user": Credentials,
		"method-bankid": BankId,
		"method-criipto": Criipto,
	},

	computed: {
		logo() {
			return _.get(window, "portal.logo_filename", null);
		},

		companyName() {
      let companyName = _.get(window, "portal.company_name");
      if (!companyName) {
        return console.error('Missing "portal.company_name" in config.');
      }
			return companyName;
		},

		loginComponent() {
			switch (this.authMethod) {
				case "bankid":
					return "method-bankid";

				case "custom_limetype":
					return "method-custom_limetype";

				case "lime_user":
					return "method-lime_user";

				case "criipto":
					return "method-criipto";
			}
		},

		notSelected() {
			return this.availableMethods.filter((m) => m.type !== this.authMethod);
		},
	},

	methods: {
		selected(method) {
			this.authMethod = method;
			if (method === "openid") {
				this.isLoading = true;
				window.location.href = this.route("openid.start").toString();
			}
		},

		logoFallback(e) {
			e.target.src = `/storage/${this.logo}`;
		},
	},
};
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}
.w-100 {
	width: 100%;
}
.fb-50 {
	flex-basis: 50%;
}
.v-expansion-panel-header {
	max-height: 52px;
	height: 52px;
	font-size: 1.25rem;
	font-weight: 600;
}
</style>
<style>
#login-card input:-webkit-autofill,
#login-card input:-internal-autofill-selected {
	background-color: inherit;
}

#login-card .v-expansion-panel-header.openid i.v-icon,
#login-card .v-expansion-panel-header.custom_limetype i.v-icon,
#login-card .v-expansion-panel-header.lime_user i.v-icon {
	color: #fff !important;
}
#login-card .v-expansion-panel-header.bankid i.mdi-chevron-down {
	color: #183e4f !important;
}
#login-card .v-expansion-panel-header.openid i.v-icon {
	transform: none !important;
}
</style>