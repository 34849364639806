<template>
  <img :src="image" class="nemid"/>
</template>


<script>
import BankIdLogo from './nemid.png';

export default {
  data() {
    return {
      image: BankIdLogo
    }
  }
}
</script>