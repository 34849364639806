var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-content",
    [
      _c(
        "v-container",
        { attrs: { fluid: "", "fill-height": "" } },
        [
          _c(
            "v-row",
            {
              staticClass: "mx-0",
              attrs: { align: "center", justify: "center" },
            },
            [
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6", md: "5", lg: "4", xl: "3" } },
                [
                  _vm.logo
                    ? _c("img", {
                        staticClass: "mb-3 d-block",
                        staticStyle: { "max-width": "200px", margin: "auto" },
                        attrs: {
                          src: "/domain/" + _vm.logo,
                          alt: _vm.companyName,
                        },
                        on: {
                          "~error": function ($event) {
                            return _vm.logoFallback($event)
                          },
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-card",
                    {
                      staticClass: "elevation-12",
                      attrs: { id: "login-card" },
                    },
                    [
                      _c("v-divider", { staticClass: "mx-4" }),
                      _vm._v(" "),
                      _c(
                        "v-card-text",
                        { staticClass: "d-flex flex-column" },
                        [
                          _c(
                            "v-expansion-panels",
                            _vm._l(
                              _vm.availableMethods,
                              function (method, index) {
                                return _c(
                                  "v-expansion-panel",
                                  {
                                    key: index,
                                    class:
                                      index !== _vm.availableMethods.length - 1
                                        ? "mb-4"
                                        : "",
                                  },
                                  [
                                    _c(
                                      "v-expansion-panel-header",
                                      {
                                        class: method,
                                        attrs: {
                                          elevation: "2",
                                          disabled: _vm.isLoading,
                                          color: _vm.providers[method].bgColor,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.selected(method)
                                          },
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "actions",
                                              fn: function () {
                                                return [
                                                  _c("v-icon", [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(
                                                          _vm.providers[method]
                                                            .panelIcon
                                                        ) +
                                                        "\n\t\t\t\t\t\t\t\t\t\t"
                                                    ),
                                                  ]),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [
                                        _vm._v(" "),
                                        method === "openid" && _vm.isLoading
                                          ? _c("v-progress-circular", {
                                              staticClass: "mr-2",
                                              attrs: {
                                                size: 20,
                                                color:
                                                  _vm.providers[method].color,
                                                indeterminate: "",
                                              },
                                            })
                                          : _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex align-center",
                                              },
                                              [
                                                _vm.providers[
                                                  method
                                                ].logo.indexOf("/") != false
                                                  ? _c(
                                                      "v-icon",
                                                      {
                                                        staticClass: "mr-4",
                                                        attrs: {
                                                          size: "36px",
                                                          color:
                                                            _vm.providers[
                                                              method
                                                            ].color,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                            _vm._s(
                                                              _vm.providers[
                                                                method
                                                              ].logo
                                                            ) +
                                                            "\n\t\t\t\t\t\t\t\t\t\t"
                                                        ),
                                                      ]
                                                    )
                                                  : _c("v-img", {
                                                      staticClass: "mr-4",
                                                      attrs: {
                                                        contain: "",
                                                        "max-height": "36px",
                                                        "max-width":
                                                          _vm.providers[method]
                                                            .maxWidth,
                                                        src: _vm.providers[
                                                          method
                                                        ].logo,
                                                      },
                                                    }),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  {
                                                    style:
                                                      "color: " +
                                                      _vm.providers[method]
                                                        .color +
                                                      ";",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.providers[method]
                                                          .text
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _vm.authMethod !== "openid"
                                      ? _c(
                                          "v-expansion-panel-content",
                                          { key: index },
                                          [
                                            _c(_vm.loginComponent, {
                                              key: _vm.authMethod,
                                              tag: "LoginMethod",
                                              attrs: {
                                                "auth-method": _vm.authMethod,
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }