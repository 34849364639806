export default {
    common: {
        loading: 'Laster...',
        somethingWentWrong: 'Noe gikk galt!',
        close: 'Lukk',
        submit: 'Send',
        cancel: 'Avbryt',
        ok: 'OK',
        refresh: 'Oppdater',
        yes: 'Ja',
        no: 'Nei',
        enter: 'Fyll inn',
        create: 'Opprett'
    },
    limetype: {
        saveChanges: 'Lagre endringer',
        noChanges: 'Ingen endringer gjort',
        updateSuccess: 'Suksess! Objektet ble oppdatert',
        requestError: code => `Forespørselen mislyktes med statuskode: ${code}`,
        error: 'Noe gikk galt. Vennligst prøv igjen og kontakt en administrator hvis problemet vedvarer.',
        filter: 'Filter',
        noData: 'Ingen data funnet'
    },
    auth: {
        login: 'Logg inn',
        loginUsingBankId: 'Logg inn med BankID',
        loginUsingCredentials: 'Logg på med brukernavn og passord',
        loginWith: 'Logg in med',
        userDetails: 'Brukeropplysninger',
        forgotPassword: 'Har du glemt passordet?',
        forgotPasswordInfo: "Skriv inn ditt brukernavn, så sender vi deg en lenke til en side der du kan tilbakestille passordet ditt.",
        resetLinkSent: 'Vi har sendt deg en e-post med en lenke for tilbakestilling av passordet!',
        usernameLabel: 'Brukernavn',
        passwordLabel: 'Passord',
        loginSuccess: 'Du er nå logget inn!',
        personalNumberInputLabel: 'DDMMÅÅNNNNN',
        personalNumberLabel: 'Personnummer',
        emailLabel: 'E-post',
        emailInputLabel: 'Skriv inn e-postadressen din',
        resetPassword: 'Tilbakestill passordet ditt',
        passwordLabel: 'Passord',
        newPasswordLabel: 'Nytt passord',
        newPasswordInputLabel: 'Skriv inn nytt passord',
        confirmPasswordLabel: 'Bekreft passord',
        resetPasswordSuccess: 'Ditt passord har blitt tilbakestilt. Du blir nå omdirigert til påloggingssiden der du kan logge inn med ditt nye passord.',
        "urn:grn:authn:no:bankid": 'BankID - Mobile or Web (not working atm)',
        "urn:grn:authn:no:vipps": 'Vipps',
        "urn:grn:authn:no:bankid:central": "NO BankID - Code card",
        "urn:grn:authn:no:bankid:mobile": "NO BankID - Mobile",
        "urn:grn:authn:dk:nemid:poces": "Personlig med kodekort",
        "urn:grn:authn:dk:nemid:moces": "Ansatt med kodekort",
        "urn:grn:authn:dk:nemid:moces:codefile": "Ansatt med kodefil",
        "urn:grn:authn:se:bankid:another-device": "SE BankID - Other device",
        "urn:grn:authn:se:bankid:same-device": "",
        authMethodInputLabel: 'Velg en metode',
        authMethodLabel: 'Innloggingsmetode',
        phoneNumberLabel: 'Mobil',
        phoneNumberInputLabel: 'XXXXXXXX',
        activateAccountInfo: 'Skriv inn ditt brukernavn, så sender vi deg en lenke til en side der du kan tilbakestille passordet ditt.',
        activateAccount: 'Aktiver kontoen din',
        activationLinkSent: 'Aktiveringsmail sendt!',
        loginAgain: "Logg på igjen",
        loggedOut: "Du er nå logget ut!"
    },
    nav: {
        loggedInAs: 'Logget inn som',
        logout: 'Logg ut',
        start: 'Start',
        index: 'Liste',
        create: 'Skape'
    },
    startView: 'Startvisning',
    startViewNotConfigured: 'No start view configured. See /readme.txt in the root folder for instructions.',
    pageNotFoundTitle: 'Uff, 404',
    pageNotFoundText: 'Siden ble ikke funnet.',
    languagePicker: {
        da: "Dansk",
        de: "Tysk",
        en: "Engelsk",
        fi: "Finsk",
        nl: "Nederlandsk",
        no: "Norsk",
        sv: "Svensk"
    }
}