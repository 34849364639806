import axios from 'axios';

const doAsync = (store, { url, mutationTypes, key }) => {
  store.commit(mutationTypes.PENDING, key)
  return axios(url)
    .then(response => {
      store.commit(mutationTypes.SUCCESS, {
        data: response.data.data,
        key
      });
      return response;
    })
    .catch(error => {
      store.commit(mutationTypes.FAILURE, {
        error,
        key
      });

      Promise.reject(error);
    });
}

export default doAsync;