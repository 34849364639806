<template>
  <img :src="image"/>
</template>


<script>
import BankIdLogo from './vipps_logo.svg';

export default {
  data() {
    return {
      image: BankIdLogo
    }
  }
}
</script>