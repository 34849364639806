var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ValidationObserver",
    { ref: "obs", attrs: { tag: "form" } },
    [
      _c(
        "v-card-text",
        [
          _vm.loginMethods.length > 1
            ? _c("validation-provider", {
                attrs: {
                  name: _vm.$t("auth.authMethodLabel"),
                  rules: "required",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var errors = ref.errors
                        return _c("v-select", {
                          attrs: {
                            solo: "",
                            items: _vm.loginMethods,
                            "item-text": "name",
                            "item-value": "value",
                            label: _vm.$t("auth.authMethodInputLabel"),
                            "error-messages": errors,
                            "append-icon": "expand_more",
                            disabled: _vm.loading,
                            loading: _vm.loading,
                            name: "authentication-method",
                          },
                          model: {
                            value: _vm.form.identification_method,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "identification_method", $$v)
                            },
                            expression: "form.identification_method",
                          },
                        })
                      },
                    },
                  ],
                  null,
                  false,
                  38064572
                ),
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.form.identification_method !== null
            ? _c("validation-provider", {
                attrs: {
                  name: _vm.$t("auth.personalNumberLabel"),
                  rules:
                    _vm.form.identification_method === null
                      ? "required"
                      : _vm.settings.rule,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var errors = ref.errors
                        return _c("v-text-field", {
                          staticClass: "mb-2 auth-method",
                          attrs: {
                            solo: "",
                            clearable: "",
                            "hide-details": "auto",
                            label: _vm.$t("auth.personalNumberInputLabel"),
                            type: "tel",
                            "error-messages": errors,
                            disabled:
                              _vm.loading ||
                              _vm.form.identification_method === null,
                            loading: _vm.loading,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "prepend-inner",
                                fn: function () {
                                  return [
                                    _c("v-icon", { attrs: { size: "40" } }, [
                                      _vm._v(_vm._s(_vm.settings.logo)),
                                    ]),
                                    _vm._v(" "),
                                    _c("v-divider", {
                                      staticClass: "mx-2",
                                      attrs: { light: "", vertical: "" },
                                    }),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            1946685698
                          ),
                          model: {
                            value: _vm.form.personalNumber,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "personalNumber", $$v)
                            },
                            expression: "form.personalNumber",
                          },
                        })
                      },
                    },
                  ],
                  null,
                  false,
                  2998872824
                ),
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.form.identification_method === "urn:grn:authn:no:bankid:mobile"
            ? _c("validation-provider", {
                attrs: {
                  name: _vm.$t("auth.phoneNumberLabel"),
                  rules: "required|digits:8",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var errors = ref.errors
                        return _c("v-text-field", {
                          staticClass: "mb-2",
                          attrs: {
                            solo: "",
                            clearable: "",
                            "hide-details": "auto",
                            label: _vm.$t("auth.phoneNumberInputLabel"),
                            type: "tel",
                            "error-messages": errors,
                            disabled: _vm.loading,
                            loading: _vm.loading,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "prepend-inner",
                                fn: function () {
                                  return [
                                    _c("v-icon", { attrs: { size: "40" } }, [
                                      _vm._v("phone"),
                                    ]),
                                    _vm._v(" "),
                                    _c("v-divider", {
                                      staticClass: "mx-2",
                                      attrs: { light: "", vertical: "" },
                                    }),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            3063186259
                          ),
                          model: {
                            value: _vm.form.mobileNumber,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "mobileNumber", $$v)
                            },
                            expression: "form.mobileNumber",
                          },
                        })
                      },
                    },
                  ],
                  null,
                  false,
                  3768821498
                ),
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card-actions",
        [
          _c("v-spacer"),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              attrs: {
                color: "primary",
                type: "submit",
                loading: _vm.loading,
                disabled: _vm.loading,
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.submit($event)
                },
              },
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("auth.login")) + "\n    ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }