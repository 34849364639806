var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.languagePickerActivated === true
    ? _c("span", [
        _vm.userIsAuthenticated === false
          ? _c(
              "span",
              [
                _c("v-select", {
                  staticClass: "loginSmallSelect",
                  attrs: {
                    items: _vm.selectableLanguages,
                    "menu-props": { bottom: true },
                    "hide-details": "",
                    "single-line": "",
                    "clear-icon": "",
                    "persistent-hint": true,
                    dense: "",
                    outlined: "",
                    filled: "",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "selection",
                        fn: function (ref) {
                          var item = ref.item
                          var index = ref.index
                          return [
                            _c("img", {
                              staticClass: "loginFlag",
                              attrs: { src: item.flag },
                            }),
                          ]
                        },
                      },
                      {
                        key: "item",
                        fn: function (ref) {
                          var item = ref.item
                          return [
                            _c("img", {
                              staticClass: "loginFlag",
                              attrs: { src: item.flag },
                            }),
                            _vm._v(
                              "\n        " +
                                _vm._s(_vm.$t("languagePicker." + item.value)) +
                                "\n      "
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    4111455774
                  ),
                  model: {
                    value: _vm.selected,
                    callback: function ($$v) {
                      _vm.selected = $$v
                    },
                    expression: "selected",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.userIsAuthenticated === true
          ? _c(
              "span",
              [
                _c(
                  "v-list",
                  { attrs: { dense: "" } },
                  [
                    _c(
                      "v-list-group",
                      {
                        attrs: {
                          "no-action": "",
                          "active-class": "white--text",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "prependIcon",
                              fn: function () {
                                return [
                                  _c("img", {
                                    staticClass: "prependFlag",
                                    attrs: { src: _vm.getSelectedFlagUrl },
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "activator",
                              fn: function () {
                                return [
                                  _c("v-list-item-title", [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(
                                          _vm.$t(
                                            "languagePicker." + _vm.selected
                                          )
                                        ) +
                                        "\n          "
                                    ),
                                  ]),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          2360964217
                        ),
                        model: {
                          value: _vm.expanded,
                          callback: function ($$v) {
                            _vm.expanded = $$v
                          },
                          expression: "expanded",
                        },
                      },
                      [
                        _vm._v(" "),
                        _vm._v(" "),
                        _c(
                          "span",
                          _vm._l(_vm.selectableLanguages, function (item, idx) {
                            return _c(
                              "v-list-item",
                              {
                                key: "language-picker-item-" + idx,
                                attrs: {
                                  exact: true,
                                  "active-class": "white--text",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.menuUpdateSelected(item)
                                  },
                                },
                              },
                              [
                                _c("v-list-item-title", [
                                  _c("img", {
                                    staticClass: "loginFlag",
                                    attrs: { src: item.flag },
                                  }),
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(item.text) +
                                      "\n            "
                                  ),
                                ]),
                              ],
                              1
                            )
                          }),
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("v-divider"),
              ],
              1
            )
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }