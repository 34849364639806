export default {
  darkMode: "Mørk tilstand",
  helpdeskTileMessage: "Nya erenden idag",
  messageTile: {
    singular: "ny besked",
    plural: "nye beskeder"
  },
  limetypeInspector: {
    labelFieldtype: "Vis kun felter, der indeholder en felttype af...",
    labelObject: "Objekt",
    title: "Gennemse Lime-skemaet...",
    properties: "Ejendomme:",
  },
  menu: {
    coworkers: "Coworkers (da)",
    deals: "Show deals (da)",
    helpdeskShow: "Show tickets (da)",
    helpdeskCreate: "Create ticket (da)",
    pagination: "Paginering (da)",
    form: "Form #1 (da)",
    grouped: "Grouped (da)",
  }
}