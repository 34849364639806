export default {
  darkMode: "Dunkler modus",
  helpdeskTileMessage: "Heute neue Fälle",
  messageTile: {
    singular: "neue nachricht",
    plural: "neue nachrichten"
  },
  limetypeInspector: {
    labelFieldtype: "Nur Felder anzeigen, die einen Feldtyp von enthalten...",
    labelObject: "Objekt",
    title: "Durchsuchen Sie das Lime-Schema...",
    properties: "Eigenschaften:",
  },
  menu: {
    coworkers: "Coworkers (de)",
    deals: "Show deals (de)",
    helpdeskShow: "Show tickets (de)",
    helpdeskCreate: "Create ticket (de)",
    pagination: "Paginering (de)",
    form: "Form #1 (de)",
    grouped: "Grouped (de)",
  }
}