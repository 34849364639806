export default {
    common: {
        loading: 'Laddar..',
        somethingWentWrong: 'Något gick fel!',
        close: 'Stäng',
        submit: 'Skicka',
        cancel: 'Avbryt',
        ok: 'OK',
        refresh: 'Uppdatera',
        yes: 'Ja',
        no: 'Nej',
        enter: 'Ange',
        create: 'Skapa'
    },
    limetype: {
        saveChanges: 'Spara ändringar',
        noChanges: 'Inga ändringar gjorda',
        updateSuccess: 'Lyckades! Objektet har uppdaterats',
        requestError: code => `Anropet misslyckades med statuskod ${code}`,
        error: 'Något gick fel, var vänlig försök igen och kontakta administratör om problemet kvarstår',
        filter: 'Filtrera',
        noData: 'Ingen data hittades'
    },
    auth: {
        login: 'Logga in',
        loginUsingBankId: 'Logga in med BankID',
        loginUsingCredentials: "Logga in med användarnamn och lösenord",
        loginWith: "Logga in med",
        userDetails: 'Användaruppgifter',
        forgotPassword: 'Glömt lösenordet?',
        forgotPasswordInfo: 'Ange ditt användarnamn så skickar vi en länk till en sida där du kan återställa ditt lösenord.',
        resetLinkSent: 'Ett återställningsmail har skickats!',
        usernameLabel: 'Användarnamn',
        passwordLabel: 'Lösenord',
        loginSuccess: 'Du är nu inloggad!',
        personalNumberInputLabel: 'ÅÅÅÅMMDDNNNN',
        personalNumberLabel: 'Personnummer',
        emailLabel: 'E-postadress',
        emailInputLabel: 'Ange din e-postadress',
        resetPassword: 'Återställ lösenord',
        newPasswordLabel: 'Nytt lösenord',
        newPasswordInputLabel: 'Välj ett nytt lösenord',
        confirmPasswordLabel: 'Bekräfta lösenord',
        resetPasswordSuccess: 'Ditt lösenord har återställts! Du skickas snart till login-sidan där du kan logga in med ditt nya lösenord',
        "urn:grn:authn:no:bankid": 'Mobil eller web',
        "urn:grn:authn:no:vipps": 'Vipps',
        "urn:grn:authn:no:bankid:central": "Norskt BankID - Kodbricka",
        "urn:grn:authn:no:bankid:mobile": "Norskt BankID - Mobil",
        "urn:grn:authn:dk:nemid:poces": "Personlig med kodkort",
        "urn:grn:authn:dk:nemid:moces": "Anställd med kodkort",
        "urn:grn:authn:dk:nemid:moces:codefile": "Anställd med kodfil",
        "urn:grn:authn:se:bankid:another-device": "BankID på en annan enhet",
        "urn:grn:authn:se:bankid:same-device": "BankID på den här enheten",
        authMethodInputLabel: 'Välj en metod',
        authMethodLabel: 'Inloggningsmetod',
        phoneNumberLabel: 'Mobil',
        phoneNumberInputLabel: 'Telefonnummer',
        activateAccountInfo: "Ange ditt användarnamn så skickar vi en länk till en sida där du får sätta ditt lösenord",
        activateAccount: "Aktivera ditt konto",
        activationLinkSent: "Aktiveringsmail har skickats!",
        loginAgain: "Logga in igen",
        loggedOut: "Du är utloggad!",
        selectEntityTitle: "Byt entitet", // title text on entity page
        selectEntityButtonText: "Fortsätt", // button text for "submit"
    },
    nav: {
        loggedInAs: 'Inloggad som',
        logout: 'Logga ut',
        start: 'Start',
        index: 'Lista',
        create: 'Skapa',
        switchEntity: "Byt entitet",
    },
    startView: 'Startvy',
    startViewNotConfigured: 'Ingen startvy konfigurerad, se /readme.txt i rotkatalogen för instruktioner.',
    pageNotFoundTitle: 'Whoops, 404',
    pageNotFoundText: 'Sidan kunde inte hittas',
    languagePicker: {
        da: "Danska",
        de: "Tyska",
        en: "Engelska",
        fi: "Finska",
        nl: "Nederländska",
        no: "Norska",
        sv: "Svenska"
    }
}