export default {
  darkMode: "Mörkt läge",
  helpdeskTileMessage: "Nya ärenden idag",
  messageTile: {
    singular: "nytt meddelande",
    plural: "nya meddelanden"
  },
  limetypeInspector: {
    labelFieldtype: "Visa endast fält som innehåller en fälttyp av...",
    labelObject: "Objekt",
    title: "Bläddra i Lime schemat...",
    properties: "Egenskaper:",
  },
  menu: {
    coworkers: "Coworkers (sv)",
    deals: "Show deals (sv)",
    helpdeskShow: "Show tickets (sv)",
    helpdeskCreate: "Create ticket (sv)",
    pagination: "Paginering (sv)",
    form: "Form #1 (sv)",
    grouped: "Grouped (sv)",
  }
}