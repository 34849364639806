<style scoped>
.loginSmallSelect {
  width: 4.5rem !important;
  margin-left: 0.5rem !important;
}
.loginFlag {
  height: 14px !important;
  width: 19px !important;
  margin-right: 0.5rem !important;
  border-radius: 10% !important;
}
.prependFlag {
  border-radius: 50% !important;
  min-width: 10% !important;
}
div.v-list-item.v-list-item--link {
  min-height: 30px !important;
  max-height: 30px !important;
}
</style>
<template>
  <span v-if="languagePickerActivated === true">
    <!-- auth/login view -->
    <span v-if="userIsAuthenticated === false">
      <v-select
        v-model="selected"
        :items="selectableLanguages"
        :menu-props="{bottom:true}"
        hide-details
        single-line
        clear-icon
        :persistent-hint="true"
        class="loginSmallSelect"
        dense
        outlined
        filled
      >
        <!-- selected item -->
        <template v-slot:selection="{ item, index }">
          <img :src="item.flag" class="loginFlag" />
        </template>
        <!-- dropdown items -->
        <template v-slot:item="{ item }">
          <img :src="item.flag" class="loginFlag" />
          {{ $t(`languagePicker.${item.value}`) }}
        </template>
      </v-select>
    </span>
    <!-- logged in / menu item view -->
    <span v-if="userIsAuthenticated === true">
      <v-list dense>
        <v-list-group v-model="expanded" no-action active-class="white--text">
          <!-- flag -->
          <template v-slot:prependIcon>
            <img :src="getSelectedFlagUrl" class="prependFlag" />
          </template>
          <!-- selected -->
          <template v-slot:activator @click="this.expanded = this.expanded ? false : true">
            <v-list-item-title>
              {{ $t(`languagePicker.${selected}`) }}
            </v-list-item-title>
          </template>
          <!-- selectable, without span items become centered instead of left-aligned -->
          <span>
            <v-list-item
              v-for="(item, idx) in selectableLanguages"
              :key="`language-picker-item-${idx}`"
              :exact="true"
              active-class="white--text"
              @click="menuUpdateSelected(item)"
            >
              <v-list-item-title>
                <img :src="item.flag" class="loginFlag" />
                {{ item.text }}
              </v-list-item-title>
            </v-list-item>
          </span>
        </v-list-group>
      </v-list>
      <v-divider></v-divider>
    </span>
  </span>
</template>
<script>
export default {
  props: {
    userIsAuthenticated: {
      required: true,
      type: Boolean,
    },
    // Array containing field ref for validation user & pw/personal no
    resetFieldsAfterChange: {
      required: false,
      type: Array,
    },
  },
  computed: {
    getSelectedFlagUrl() {
      return `/assets/flags/${this.selected}.png`;
    },
    selectableLanguages() {
      // Get correct translations for each language
      // If logged in, do not display selected language as option.
      let output = this.languages
        .filter((la) => {
          return this.userIsAuthenticated ? la != this.selected : true;
        })
        .map((l) => {
          return {
            value: l,
            flag: `/assets/flags/${l}.png`,
            text: this.$t(`languagePicker.${l}`),
          };
        });
      return _.sortBy(output, ["text"]);
    },
  },
  watch: {
    selected: {
      deep: true,
      handler: function (val) {
        if (!this.languagePickerActivated || !this.languages.includes(val)) {
          return;
        }

        this.$store.commit("SET_USER_LOCALE", {
          selected: val,
          i18n: this.$i18n,
        });
        this.$store.commit("snack/HIDE");

        if (!this.userIsAuthenticated) {
          this.resetFieldValidation();
        }
      },
    },
  },
  data() {
    return {
      expanded: this.userIsAuthenticated ? false : true,
      languages: _.get(window, "portal.enabled_languages", []),
      selected: this.$store.getters.getUserLocale(),
      languagePickerActivated: _.get(window, "portal.enabled_languages", []).length > 1,
    };
  },
  methods: {
    menuUpdateSelected(item) {
      this.selected = item.value;
      this.$nextTick(() => {
        this.expanded = false;
      });
    },
    // Display validation messages in correct language after change
    resetFieldValidation() {
      this.$nextTick(() => {
        _.forEach(this.resetFieldsAfterChange, (item) => {
          try {
            item.validate();
          } catch (e) {
            console.log("Failed resetting field validation (resetRefs) e: ", e);
          }
        });
      });
    },
  }
};
</script>