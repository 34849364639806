export default {
  darkMode: "Tumma tila",
  helpdeskTileMessage: "Uusia tapauksia tänään",
  messageTile: {
    singular: "uusi viesti",
    plural: "uusia viestejä"
  },
  limetypeInspector: {
    labelFieldtype: "Näytä vain kentät, jotka sisältävät kentän tyypin...",
    labelObject: "Esine",
    title: "Selaa Lime-skeemaa...",
    properties: "Ominaisuudet:",
  },
  menu: {
    coworkers: "Coworkers (fi)",
    deals: "Show deals (fi)",
    helpdeskShow: "Show tickets (fi)",
    helpdeskCreate: "Create ticket (fi)",
    pagination: "Paginering (fi)",
    form: "Form #1 (fi)",
    grouped: "Grouped (fi)",
  }
}