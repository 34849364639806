import Vue from 'vue';
import doAsync from './../async-util';
import types from './mutation-types';

export default {
  state: {
    schema: _.has(window, 'lime.schema') ? _.cloneDeep(window.lime.schema) : null
  },

  getters: {
    schema: (state) => {
      return state.schema;
    },

    limetypeIndexData: (state) => (key) => {
      return _.get(state, `${key}_${types.LIMETYPE_INDEX.stateKey}`, []);
    },

    limetypeIndexPending: (state) => (key) => {
      return _.get(state, `${key}_${types.LIMETYPE_INDEX.loadingKey}`);
    },

    limetypeShowData: (state) => (key) => {
      return _.get(state, `${key}_${types.LIMETYPE_SHOW.stateKey}`, []);
    },

    limetypeShowPending: (state) => (key) => {
      return _.get(state, `${key}_${types.LIMETYPE_SHOW.loadingKey}`);
    },

    limetypeRelationPending: (state) => (key) => {
      return _.get(state, `${key}_${types.LIMETYPE_RELATION.loadingKey}`);
    },

    limetypeRelationData: (state) => (key) => {
      return _.get(state, `${key}_${types.LIMETYPE_RELATION.stateKey}`, []);
    }
  },

  mutations: {

    // Index mutations
    [types.LIMETYPE_INDEX.SUCCESS](state, { data, key }) {
      Vue.set(state, `${key}_${types.LIMETYPE_INDEX.loadingKey}`, false);
      Vue.set(state, `${key}_${types.LIMETYPE_INDEX.stateKey}`, data);
      Vue.set(state, `${key}_${types.LIMETYPE_INDEX.lastFetched}`, Date.now());
    },

    [types.LIMETYPE_INDEX.PENDING](state, key) {
      Vue.set(state, `${key}_${types.LIMETYPE_INDEX.loadingKey}`, true)
    },

    [types.LIMETYPE_INDEX.FAILURE](state, { key, error }) {
      Vue.set(state, `${key}_${types.LIMETYPE_INDEX.loadingKey}`, false);;
      this.commit(types.SET_SNACK, error);
    },

    // Show mutations
    [types.LIMETYPE_SHOW.SUCCESS](state, { key, data }) {
      Vue.set(state, `${key}_${types.LIMETYPE_SHOW.loadingKey}`, false);
      Vue.set(state, `${key}_${types.LIMETYPE_SHOW.stateKey}`, data);
      Vue.set(state, `${key}_${types.LIMETYPE_SHOW.lastFetched}`, Date.now());
    },

    [types.LIMETYPE_SHOW.PENDING](state, key) {
      Vue.set(state, `${key}_${types.LIMETYPE_SHOW.loadingKey}`, true)
    },

    [types.LIMETYPE_SHOW.FAILURE](state, { key, error }) {
      Vue.set(state, `${key}_${types.LIMETYPE_SHOW.loadingKey}`, false);
      this.commit(types.SET_SNACK, error);
    },

    // Relation mutations
    [types.LIMETYPE_RELATION.SUCCESS](state, { key, data }) {
      Vue.set(state, `${key}_${types.LIMETYPE_RELATION.loadingKey}`, false);
      Vue.set(state, `${key}_${types.LIMETYPE_RELATION.stateKey}`, data);
      Vue.set(state, `${key}_${types.LIMETYPE_RELATION.lastFetched}`, Date.now());
    },

    [types.LIMETYPE_RELATION.PENDING](state, key) {
      Vue.set(state, `${key}_${types.LIMETYPE_RELATION.loadingKey}`, true)
    },

    [types.LIMETYPE_RELATION.FAILURE](state, { key, error }) {
      Vue.set(state, `${key}_${types.LIMETYPE_RELATION.loadingKey}`, false);
      this.commit(types.SET_SNACK, error);
    }
  },

  actions: {
    limetypeIndex(store, limetype) {
      return doAsync(store, {
        url: this.route('limetype.index', limetype, {}).toString(),
        mutationTypes: types.LIMETYPE_INDEX,
        key: limetype
      });
    },

    limetypeShow(store, { limetype, id }) {
      return doAsync(store, {
        url: this.route('limetype.show', [limetype, id]).toString(),
        mutationTypes: types.LIMETYPE_SHOW,
        key: limetype
      });
    },

    limetypeRelation(store, { view, type, relationType, relatedLimetype }) {
      const url = this.route('limetype.get-related', {
        view,
        type,
        relationType,
        relatedLimetype
      }).toString();

      const key = `${view}_${type}_${relationType}_${relatedLimetype}`;

      return doAsync(store, {
        url,
        mutationTypes: types.LIMETYPE_RELATION,
        key
      });
    },
  }
};