<template>
  <v-row align="center" justify="center">
    <v-col cols="12" sm="12" md="6">
      <form action="">
        <img v-if="logo" :src="`/domain/${logo}`" @error.once="logoFallback" class="mb-3 d-block" :alt="companyName"
          style="max-width: 200px; margin: auto" />
        <v-card>
          <v-card-title>{{ $t('auth.selectEntityTitle') }}</v-card-title>
          <v-card-text>
            <v-select v-model="selectedEntity" :items="entities" item-text="descriptive" item-value="id"></v-select>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" type="submit" :loading="loading" :disabled="loading" @click.prevent="submit">{{
              $t('auth.selectEntityButtonText') }}</v-btn>
          </v-card-actions>
        </v-card>
      </form>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios';
import { find } from 'lodash';

export default {
  props: {
    entities: {
      type: Array,
      required: true,
      default: () => []
    }
  },

  data() {
    return {
      selectedEntity: null,
      loading: false,
    };
  },

  computed: {
    logo() {
      return _.get(window, "portal.logo_filename", null);
    },

    currentEntity() {
      return _.get(window, "lime.user.entity.descriptive", "");
    },

    companyName() {
      let companyName = _.get(window, "portal.company_name");
      if (!companyName) {
        console.error('Missing "portal.company_name" in config.');
      }
      return companyName;
    },
  },

  methods: {
    logoFallback(e) {
      e.target.src = `/storage/${this.logo}`;
    },

    makeRequest(id) {
      this.loading = true;
      const url = this.route("set-user-entity").toString();
      axios.post(url, {
        entityId: id
      })
        .then((res) => {
          window.location.href = window.location.origin;
        })
        .catch((err) => {
          console.error(err);
          this.loading = false;
        });
    },

    submit() {
      const selected = find(this.entities, (entity) => entity.id === this.selectedEntity);

      this.makeRequest(selected.id);
    },

    setDefaultEntity() {
      this.selectedEntity = this.entities[0]?.id;
    }
  },

  mounted() {
    this.setDefaultEntity();
  }
};
</script>
