<template>
	<v-content>
		<v-container fluid fill-height>
			<v-row align="center" justify="center" class="mx-0">
				<v-col cols="12" sm="6" md="5" lg="4" xl="3">
					<img
						:src="`/domain/${logo}`"
						v-if="logo"
						class="mb-3 d-block"
						style="max-width: 200px; margin: auto"
					/>
					<ValidationObserver ref="obs" tag="form">
						<v-card class="elevation-12" id="reset-password-card">
							<v-toolbar dark color="primary" flat>
								<v-toolbar-title>{{
									$t("auth.resetPassword")
								}}</v-toolbar-title>
							</v-toolbar>
							<v-card-text>
								<validation-provider
									:name="$t('auth.usernameLabel')"
									rules="required"
								>
									<v-text-field
										prepend-icon="person"
										name="password"
										:label="$t('auth.usernameLabel')"
										v-model="form.username"
										slot-scope="{ errors }"
										:error-messages="errors"
										:loading="loading"
										:disabled="loading || disableForm"
                                        :placeholder="isAutoFilled ? ' ' : ''"
									></v-text-field>
								</validation-provider>

								<validation-provider
									:name="$t('auth.newPasswordLabel')"
									:rules="passwordValidationRule"
									:vid="$t('auth.newPasswordLabel')"
								>
									<v-text-field
										prepend-icon="lock"
										name="password"
										:label="$t('auth.newPasswordInputLabel')"
										type="password"
										v-model="form.password"
										slot-scope="{ errors }"
										:error-messages="errors.length && passwordRegex !== null ? passwordErrorMessage || errors : errors"
										:loading="loading"
										:disabled="loading || disableForm"
                                        :placeholder="isAutoFilled ? ' ' : ''"
									></v-text-field>
								</validation-provider>

								<validation-provider
									:name="$t('auth.confirmPasswordLabel')"
									:rules="`required|confirmed:${$t('auth.newPasswordLabel')}`"
								>
									<v-text-field
										prepend-icon="lock"
										name="password"
										:label="$t('auth.confirmPasswordLabel')"
										type="password"
										v-model="form.password_confirmation"
										slot-scope="{ errors }"
										:error-messages="errors"
										:loading="loading"
										:disabled="loading || disableForm"
                                        :placeholder="isAutoFilled ? ' ' : ''"
									></v-text-field>
								</validation-provider>
							</v-card-text>
							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn
									color="primary"
									type="submit"
									:loading="loading"
									:disabled="loading || disableForm"
									@click.prevent="submit"
								>
									{{ $t("common.submit") }}
								</v-btn>
							</v-card-actions>
						</v-card>
					</ValidationObserver>
				</v-col>
			</v-row>
		</v-container>
	</v-content>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
	props: {
		resetToken: {
			type: String,
			required: true,
		},
        validation: {
            type: Object,
            default: null
        }
	},

	components: {
		ValidationObserver,
		ValidationProvider,
	},

	computed: {
		logo() {
			return _.get(window, "portal.logo_filename", null);
		},
		passwordRegex() {
			let rgx = _.get(this.validation, "password_regex", null);
			return rgx === null ? null : decodeURI(rgx); // decode to removed escaped chars
		},

		passwordValidationRule() {
			return this.passwordRegex === null
				? { required: true, min: 6 }
				: { required: true, regex: new RegExp(this.passwordRegex) };
		},

        passwordErrorMessage(){
            return _.get(this.validation, `messages.${window.portal.locale}`, '');
            return _.get(window, `portal.auth.messages.${window.portal.locale}`, '');
        }
	},

	data() {
		return {
			disableForm: false,
			loading: false,
			form: {
				username: "",
				password: "",
				password_confirmation: "",
			},
			isAutoFilled: false,
		};
	},

	methods: {
		async submit() {
			let valid = await this.$refs.obs.validate();

			if (!valid) return;

			this.loading = true;

			try {
				const url = this.route(
					"custom-limetype.reset.request",
					this.resetToken
				);

				await axios.post(url.toString(), {
					username: this.form.username,
					password: this.form.password,
					password_confirmation: this.form.password_confirmation,
				});

				this.disableForm = true;

				this.$store.commit("snack/SET", {
					message: this.$t("auth.resetPasswordSuccess"),
					color: "success",
					showCloseButton: false,
					timeout: 0,
				});

				setTimeout(
					() => (window.location.href = this.route("login").toString()),
					5000
				);
			} catch (err) {
				this.$store.commit("snack/SET", {
					message: this.$t("common.somethingWentWrong"),
					color: "error",
					showCloseButton: false,
					timeout: 2000,
				});
			}

			this.loading = false;
		},
	},

	beforeCreate() {
		this.$nextTick(() => {
			let ival = setInterval(() => {
				let els = this.$el.querySelectorAll("input");
				if (els.length) {
					clearInterval(ival);
					els.forEach((el) => {
						el.addEventListener("transitionstart", (e) => {
							if (e.propertyName === "background-color") {
								this.isAutoFilled = true;
							}
						});
						el.addEventListener("animationstart", (e) => {
							if (e.animationName === "onAutoFillStart") {
								this.isAutoFilled = true;
							}
						});
					});
				}
			}, 50);
		});
	},

    mounted(){
        console.log(this.validation);
    }
};
</script>
<style>
@keyframes onAutoFillStart {
	from {
		/**/
	}
	to {
		/**/
	}
}
#reset-password-card input:-webkit-autofill,
#reset-password-card input:-internal-autofill-selected {
	animation-name: onAutoFillStart;
	background-color: inherit;
	transition: background-color 50000s ease-in-out 0s;
}
</style>
